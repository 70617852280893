import React, { useEffect } from "react"
import { Col, Row } from "react-bootstrap"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import { device } from "../../constants/breakpoints"
import { colors } from "../../constants/Colors"
import { startFetchingChiSiamoAsync } from "../../redux/chisiamoSlice"
import CustomButton from "../Atoms/Button"
import FaqsContainer from "../FaqsContainer/FaqsContainer"

export const descrizioneMock =
  "Lorem ipsum dolor sit amet, consecteur adipiscing elit. Pellentesque lacinia nunc vel commodo ultrices. Nulla vehicula, ex sed tempus pharetra, dolor tincidunt magna, ut ornare risus nisl quis arcum. Duis nec euisomd dui a mollis"

const InnerItems = [
  {
    title: "Cerca il tuo specialista solo tra i migliori",
    content: descrizioneMock,
  },
  {
    title: "Consulta il loro profilo",
    content: descrizioneMock,
  },
  {
    title: "Prenota una visita",
    content: descrizioneMock,
  },
  {
    title: "Prenota un videoconsulto",
    content: descrizioneMock,
  },
  {
    title: "Account privato",
    content: descrizioneMock,
  },
  {
    title: "Tutto sui tratatmenti",
    content: descrizioneMock,
  },
]

const Container = styled.div`
  border-top: 20px solid black;
  border-top-color: ${({ theme }) => theme.ocean};
  display: flex;
  background: #f7f7f7;
  flex-direction: column;
`

const TitleWrapper = styled.div`
  display: flex;
  background: #f7f7f7;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
`

const Title = styled.h1`
  color: ${({ theme }) => theme.blackblue};
`
const SubTitle = styled.p`
  color: ${({ theme }) => theme.lightblue};
  text-transform: uppercase;
  margin-bottom: 0;
`

const InnerContainer = styled.p`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 10%;
  margin-right: 10%;
  border-radius: 6px;
  margin-bottom: 0;
  padding: 20px;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 0;
  padding-bottom: 120px;

  @media ${device.tablet} { {
    margin: 0;
  }
`

const InnerItemWrapper = styled.div`
  margin-top: 50px;
`

const InnerItemImage = styled.img`
  height: 50px;
  width: auto;
`

const InnerItemTitle = styled.p`
  margin-top: 10px;
  font-weight: 500;
  font-size: 22px;
  color: ${({ theme }) => theme.midblue};
`

const InnerItemContent = styled.p``

const Background = styled(Row)`
  min-height: 150px;
  --bs-gutter-x: 0;
  object-fit: cover;
  background-image: url(${props => props.img});
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
`
const Feature = styled.div`
  margin: auto;
  text-align: center;
  @media ${device.tablet} {
    margin-bottom: 50px;
    padding-top: 50px;
  }
`
const FeatureTitle = styled.h3`
  color: white;
`

const FeatureSubTitle = styled.h5`
  color: white;
`

const InnerItem = ({ image, title, content, index }) => {
  return (
    <InnerItemWrapper data-aos="fade-up" data-aos-delay={100 * index}>
      <InnerItemTitle>
        <span
          style={{
            fontSize: 32,
            fontWeight: 700,
            color: colors.LIGHT_BLUE,
            marginBottom: 10,
          }}
        >
          {index + 1}.{"   "}
        </span>
        {title}
      </InnerItemTitle>
      <InnerItemContent>{content}</InnerItemContent>
    </InnerItemWrapper>
  )
}

export default function WhyUsContainer() {
  return (
    <Container>
      <TitleWrapper>
        <Title>Perchè Chirurghi estetici?</Title>
        <SubTitle>Scopri tutti i vantaggi del nostro portale</SubTitle>
      </TitleWrapper>
      <InnerContainer>
        {InnerItems.map((item, index) => (
          <InnerItem index={index} {...item} />
        ))}
      </InnerContainer>
      <Background img={require("../../images/background_chi_siamo.png")}>
        <Col md={3}>
          <Feature>
            <FeatureTitle>Scelte Qualificate</FeatureTitle>
          </Feature>
        </Col>
        <Col md={3}>
          <Feature>
            <FeatureTitle>Storie di pazienti veri</FeatureTitle>
          </Feature>
        </Col>
        <Col md={3}>
          <Feature>
            <FeatureTitle>Trasparenza</FeatureTitle>
          </Feature>
        </Col>
      </Background>
      <FaqsContainer />
    </Container>
  )
}
