import React, { useState } from "react"
import styled from "styled-components"
import { device } from "../../constants/breakpoints"
import { GlobalRow } from "../../constants/global-styles"
export const descrizioneMock =
  "Lorem ipsum dolor sit amet, consecteur adipiscing elit. Pellentesque lacinia nunc vel commodo ultrices. Nulla vehicula, ex sed tempus pharetra, dolor tincidunt magna, ut ornare risus nisl quis arcum. Duis nec euisomd dui a mollis"

const Faqs = [
  {
    title: "Come posso richiedere un preventivo",
    body: descrizioneMock,
  },
  {
    title: "In cosa consiste la videoconsulenza",
    body: descrizioneMock,
  },
  {
    title: "Come posso scrivere una recensione sulla mia esperienza?",
    body: descrizioneMock,
  },
]

const Container = styled.div`
  background: white;
  padding-bottom: 260px;
  padding-top: 50px;

  @media ${device.tablet} {
    padding: 20px;
  }
`

const Wrapper = styled.div`
  max-width: 800px;

  margin: auto;
`
const Sub = styled.p`
  color: ${({ theme }) => theme.blackblue};
  font-size: 18px;
  font-weight: 500;
`

const Title = styled.h1`
  color: ${({ theme }) => theme.blackblue};
  font-weight: 700;
`
const FaqItemContainer = styled.div`
  box-shadow: 5px 5px 20px #efefef;
  border-radius: 12px;
  margin-top: 20px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  max-height: ${({ open }) => (open ? "300px" : "70px")};
  overflow: hidden;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  @media ${device.tablet} {
    margin-top: 15px;
    max-height: ${({ open }) => (open ? "300px" : "60px")};
  }
`

const FaqItemContent = styled.p`
  @media ${device.tablet} {
    font-size: 12px;
  }
`
const FaqItemChevron = styled.h5`
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
  margin-left: auto;
  padding-left: 20px;
  ::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid;
    border-right: 2px solid;
    transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(-135deg)")};
    right: 6px;
    top: 4px;
  }
`

const FaqItemTitle = styled.h5`
  font-size: 18px;
  font-weight: 500;
  padding-top: 15px;
  padding-bottom: 15px;
  color: ${({ theme }) => theme.darkblue};

  @media ${device.tablet} {
    ${({ open }) =>
      !open &&
      `
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
      `}
    font-size: 14px;
  }
`

const FaqItem = ({ title, body }) => {
  const [open, setOpen] = useState(false)
  return (
    <FaqItemContainer open={open} onClick={() => setOpen(!open)}>
      <GlobalRow>
        <FaqItemTitle open={open}>{title}</FaqItemTitle>
        <FaqItemChevron open={open} />
      </GlobalRow>
      <FaqItemContent>{body}</FaqItemContent>
    </FaqItemContainer>
  )
}

export default function FaqsContainer() {
  return (
    <Container>
      <Wrapper>
        <Sub>FAQ'S</Sub>
        <Title>Domande Frequenti</Title>
        {Faqs.map((item, i) => (
          <>
            <FaqItem key={"faq" + i} {...item} />
          </>
        ))}
      </Wrapper>
    </Container>
  )
}
