import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "../constants/global.css"

import { useBreadcrumb } from "gatsby-plugin-breadcrumb"
import ChiSiamoContainer from "../components/ChiSiamoContainer/ChiSiamoContainer"
import Aos from "aos"
import "aos/dist/aos.css"
import WhyUsContainer from "../components/WhyUsContainer/WhyUsContainer"

const WhyUsPage = ({ location }) => {
  React.useEffect(() => {
    Aos.init()
  }, [])
  return (
    <Layout>
      <SEO title="Perchè sceglierci" />
      <WhyUsContainer />
    </Layout>
  )
}

export default WhyUsPage
